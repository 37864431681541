const getPrefix = () => {
  if (typeof window === 'undefined') return
  const WEB_CLIENT = window?.gbCommonInfo?.WEB_CLIENT || ''
  const webBrand = WEB_CLIENT === 'romwe' ? 'R' : 'S'
  const terminal = 'Pc'
  const prefix = `${webBrand}${terminal}`
  return prefix
}

export function addPrefixPoskey (poskeys = []) {
  if (!Array.isArray(poskeys)) {
    poskeys = poskeys.split(',')
  }
  
  function _getPosKey(posKey) {
    if (!posKey) return
    const prefix = getPrefix()
    if (posKey.indexOf(prefix) === 0) return posKey
    return `${prefix}${posKey}`
  }

  return poskeys
    .map(k => _getPosKey(k))
    .filter(v => v)
    .join(',')
}

export const romwePoskey = (key) => {
  const prefix = getPrefix()
  return key.indexOf(prefix) === 0 ? key.replace(prefix, '') : key
}
