import { replaceHrefSpecChar } from '@shein/common-function'

export default {
  getTitle(url) {
    const vscReg = /([^\/]+)-[vs]?c-\d+\.html/
    const promoReg = /\/?(shein-picks|promo-discount)\.html/
    const dailyReg = /\/?daily-new\.html.*daily=(\d+-\d+-\d+)/
    if (vscReg.test(url)) return url.match(vscReg)[1]
    else if (promoReg.test(url)) return url.match(promoReg)[1]
    else if (dailyReg.test(url)) return url.match(dailyReg)[1]
    else if (/\/?daily-new\.html/.test(url)) return 'whats new'
    else return ''
  },
  replaceSpeCha: (str) =>
    typeof str === 'string' && replaceHrefSpecChar({ href: str, activity: true }),
}
