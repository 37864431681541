import userPath from './userpath'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { mapGetters, mapActions } from 'vuex'
import { replaceHrefSpeCha } from './utils'
import { biClickReport, biUtils, getGoodsContentList } from './biUtil'
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { timezoneFormatFromSite } from 'activity/common/timeFormat'
import {
  getBiActSrcIdentifier,
  getBiGameSrcIdentifier,
} from 'public/src/pages/act_common/sa.js'
import {
  mergeQueryString,
  parseQueryString,
  transformImg as _transformImg,
  htmlDecode as _htmlDecode,
} from '@shein/common-function'
import { GAME_TYPES } from 'public/src/pages/act_common/const/game'
import { queryString } from 'public/src/pages/common/utils/index.js'

export const mixin = {
  value: {},
}

// 缓存专题内容，用于埋点
export const campaignContent = {}

export const initMixin = (context = {}) => {
  const {
    lang,
    langPath,
    PUBLIC_CDN,
    PUBLIC_IMG,
    GB_cssRight,
    IS_RW,
    LAZY_IMG,
    LAZY_GREY,
    LAZY_IMG_SQUARE,
    language,
    addedWishList,
    content,
    activityId,
    activityName,
    isRomwe,
    activityNameEn,
    SiteUID,
    dateLangMap,
  } = context
  const { abtParams, name_en, attention_flag, activityPaths } = content
  const scenes = `campaign_${activityNameEn}_${activityId}`
  Object.assign(campaignContent, content)
  return {
    components: {
      ClientOnly,
    },
    data() {
      return {
        isRomwe,
        abtParams,
        lang,
        langPath,
        PUBLIC_CDN,
        PUBLIC_IMG,
        GB_cssRight,
        IS_RW,
        LAZY_IMG,
        LAZY_GREY,
        LAZY_IMG_SQUARE,
        language,
        addedWishList,
        content,
        activityId,
        activityName,
        activityNameEn,
        scenes,
        hrefTypeMap: {
          1: 'pickId',
          2: 'SheinPicks',
          3: 'default',
          4: 'real',
          5: 'real',
          6: 'campaign',
        },
      }
    },
    computed: {
      ...mapGetters(['mapToSku']),
    },
    watch: {
      isLogin(val) {
        val && this.queryGoodsSaveStats()
      },
    },
    methods: {
      ...mapActions(['fetchGoodsSaveStats']),
      biClickReport,
      getGoodsContentList,
      timeFormatFromSite(time, type = 'shortDate') {
        return timezoneFormatFromSite(time, type, {
          SiteUID,
          lang,
          dateLangMap,
        })
      },
      replaceCouponTipsDate(couponData = {}) {
        try {
          const { endTime } = couponData
          if (endTime) {
            let result = this.timeFormatFromSite(+endTime, 'fullDate')
            if (result) {
              return language.SHEIN_KEY_PC_16466.replace('--/--/--', result)
            }
          }
          return ''
        } catch (error) {
          console.log('获取优惠券动态日期有误', error)
          return ''
        }
      },
      replaceCouponTipsFullDate(couponData = {}) {
        try {
          const { startTime, endTime } = couponData
          const startResult = startTime && this.repalceDate(startTime)
          const endResult = endTime && this.repalceDate(endTime)
          let result = ''
          if (startResult) {
            result += startResult + ' - '
          }
          if (endResult) {
            result += endResult
          }
          return result
        } catch (error) {
          console.log('获取优惠券动态日期有误', error)
          return ''
        }
      },
      repalceDate(time) {
        let result = ''
        if (time) {
          result = this.timeFormatFromSite(+time, 'fullDate')
        }
        return result
      },
      getGoodsUrl(name, id) {
        //拼接商品详情路由
        const urlName = name || ''
        const href = `/${urlName
          .replace('/', '-')
          .split(' ')
          .join('-')}-p-${id}.html`
        let url = replaceHrefSpeCha(href)
        return url
      },
      autoAddIci(payload) {
        let { url, ici = '', isExternalLink = '0', mallCode = '' } = payload
        if (!url || !ici || url === 'javascript:;') return 'javascript:;'
        // 外部链接则不拼接参数
        if (!!+isExternalLink) return url
        if (url.replace) url = url.replace(/\s/g, '-').replace(/[#]/g, '')
        if (url.indexOf('ici=') == -1) {
          ici = encodeURIComponent(ici.replace(/&/g, '|'))
          if (url.indexOf('?') >= 0) {
            const index = url.indexOf('?') + 1
            url += (url.slice(index).length > 0 ? '&ici=' : 'ici=') + ici
          } else {
            url += '?ici=' + ici
          }
        }
        // 拼接 mallCode
        if (mallCode) {
          url += `&mallCode=${mallCode}`
        }
        return url
      },
      getMallCode(hrefType, mallCode) {
        // 选品: pickId, sheinpicks: discount_list, dailyNew: daily_new, 闪购: flash_sale, 真实分类: real，只有这五种跳转类型才有mallCode
        return (['pickId', 'discount_list', 'daily_new', 'flash_sale', 'real'].includes(hrefType) && mallCode) ? mallCode : ''
      },
      isInViewPort(element, isIntersecting = false) {
        if (!element) return
        const viewHeight =
          window.innerHeight || document.documentElement.clientHeight
        const { top, bottom, height } = element.getBoundingClientRect()
        const EXPOSURE_HEIGHT = height * 0.6
        const EXTRA_HEIGHT = 800

        // console.log('top', top)
        // console.log('bottom', bottom)
        // console.log('top >= 0', top >= 0 && bottom <= (viewHeight + EXPOSURE_HEIGHT), viewHeight + EXPOSURE_HEIGHT)
        // console.log('top < 0', top < 0 && bottom >= EXPOSURE_HEIGHT, EXPOSURE_HEIGHT)

        // return (top >= 0 && bottom <= (viewHeight + EXPOSURE_HEIGHT)) || (top < 0 && bottom >= EXPOSURE_HEIGHT)
        let isInViewPort = false
        if (height === 0) {
          return false
        }
        if (isIntersecting) {
          isInViewPort =
            (top >= 0 && top <= viewHeight + EXTRA_HEIGHT) ||
            (top < 0 && bottom >= -EXTRA_HEIGHT)
        } else {
          isInViewPort =
            (top >= 0 && bottom <= viewHeight + EXPOSURE_HEIGHT) ||
            (top < 0 && bottom >= EXPOSURE_HEIGHT)
        }

        return isInViewPort
      },
      transformImg: (img) => _transformImg({ img }),
      htmlDecode: (text) => _htmlDecode({ text }),
      handleOverflow(unableToScroll) {
        if (unableToScroll) {
          $('html>body').css('overflow', 'hidden')
        } else {
          $('html>body').css('overflow', '')
        }
      },
      getJumpUrl(hrefType = '', hrefTarget = '', cateInfo = {}, cateType = '') {
        const getWebLink = (hrefTarget) => {
          if (hrefTarget && hrefTarget.indexOf('http') === -1) {
            hrefTarget = /^\//.test(hrefTarget) ? hrefTarget : `/${hrefTarget}`
          }
          if (!hrefTarget.includes(`${langPath}/`)) {
            hrefTarget = `${langPath}${hrefTarget}`
          }
          return hrefTarget
        }
        const addPreTypes = [
          'webLink',
          'flash_sale',
          'daily_new',
          'shop_multiple_sets',
          'about_us',
          'looks_we_love',
          'survey',
          'giftcard',
          'love_romwe',
          'my_order',
          'shein_x_info',
          'prime',
          'activityNew'
        ]
        let url = ''
        if (hrefType === 'campaign') {
          try {
            const matchResult = hrefTarget.match(/campaign\/(.*)/)
            const nameEn = matchResult.length ? matchResult[1]?.split?.('?')?.[0] : ''
            const firstLevelPath = activityPaths?.filter(i => i.actNameEn === nameEn)?.[0]?.path
            if (nameEn && firstLevelPath)  {
              url = getWebLink(`/${firstLevelPath}`)
            } else {
              url = getWebLink(hrefTarget)
            }
          } catch(e) {
            console.log(e)
            url = getWebLink(hrefTarget)
          }
        } else if (GAME_TYPES.includes(hrefType)) {
          // 游戏
          url = getWebLink(`/game${hrefTarget}`)
        } else if (addPreTypes.includes(hrefType)) {
          url = getWebLink(hrefTarget)
        } else if (hrefType === 'discount_list') {
          url = transformPromodiscountUrl(
            `${langPath}/shein-picks.html` +
              (hrefTarget
                ? `?hrefTarget=${
                    Number(hrefTarget) ? hrefTarget : !isRomwe ? 1 : 9
                  }`
                : '')
          )
        } else if (hrefType === 'shein_x') {
          // 这条逻辑是关于跳转SHEINX的逻辑 相关需求文档：wiki.pageId=798232417
          url = getWebLink('sheinx')
        } else if (hrefType === 'trial_center') {
          // 这条逻辑是关于跳转试用中心的逻辑 相关需求文档：wiki.pageId=783187988
          if (attention_flag) {
            let isNotEmpty =
              attention_flag?.inProgressFlag ||
              attention_flag?.notStartFlag ||
              attention_flag?.overFlag
            url = ''
            if (attention_flag?.openFlag && isNotEmpty) {
              let tab = 2 // 默认显示正在进行中的tab
              if (attention_flag?.inProgressFlag) {
                tab = 2
              } else if (attention_flag?.notStartFlag) {
                tab = 3
              } else if (attention_flag?.overFlag) {
                tab = 1
              }
              url = getWebLink(`/free-trial-center/index?tab=${tab}`)
            }
            return url
          }
        } else if(hrefType === 'sheinxDesignerList') {
          // 这条逻辑是关于跳转设计师列表的逻辑 相关需求文档：wiki.pageId=1025157061
          url = getWebLink('/designer_list')
        } else {
          url = cateInfo[cateType]
        }
        return url || hrefTarget
      },
      // 专题，获取src_module，src_identifier，src_tab_page_id
      getBiParamCampaign(options) {
        const {
          hrefType = '',
          hrefTarget = '',
          componentName = '',
          componentTypeNameEn = '',
          position = 1,
          tagName = '',
          hotName = '',
        } = options
        if (!hrefType) return {}

        return getBiActSrcIdentifier({
          src_module: 'campaign',
          activityId,
          activityNameEn,
          componentTypeNameEn,
          componentName,
          tagName,
          hotName,
          position,
          hrefType,
          hrefTarget,
          isNeedSrcTabPageId: true,
        })
      },
      // 专题url中新增或替换专题链接中的src_module，src_identifier，src_tab_page_id
      addBiParamInUrlCampaign(options) {
        const {
          url = '',
          hrefType = '',
          hrefTarget = '',
          componentName = '',
          componentTypeNameEn = '',
          position = 1,
          tagName = '',
          hotName = '',
          isExternalLink = '0',
        } = options
        if (!hrefType || (hrefType === 'webLink' && isExternalLink === '1')) return url
        let biUrl = ''
        let src_param = getBiActSrcIdentifier({
          src_module: 'campaign',
          activityId,
          activityNameEn,
          componentTypeNameEn,
          componentName,
          tagName,
          hotName,
          position,
          hrefType,
          hrefTarget,
          isNeedSrcTabPageId: true,
        })

        biUrl = mergeQueryString({ mergeObj: { ...src_param }, url })
        return biUrl
      },
      // 游戏url中新增或替换游戏链接中的src_module，src_identifier，src_tab_page_id
      addBiParamInUrlGame(options) {
        const {
          url = '',
          activityNameEn = '',
          taskId = 0,
          hrefType = '',
          hrefTarget = '',
        } = options
        if (!hrefType) return url
        let biUrl = ''
        let src_param = getBiGameSrcIdentifier({
          src_module: 'game',
          activityNameEn,
          taskId,
          hrefType,
          hrefTarget,
          isNeedSrcTabPageId: true,
        })

        biUrl = mergeQueryString({ mergeObj: { ...src_param }, url })
        return biUrl
      },
      getGaListName: (propData) => {
        const { component_id, name, id } = propData
        return `campaign=${activityNameEn}/${activityId}_CT=${component_id}_CN=${name}_CI=${id}`
      },
      changeToNewType(t, val) {
        const newTypeMap = {
          discount_list: `${isRomwe ? 'Promo discount' : 'SheinPicks'}_${
            val || 0
          }`,
          daily_new: 'dailynew',
          flash_sale: 'FlashSale',
          show_checkin: 'SignIn',
          giftcard: 'GiftCard',
          love_romwe: 'Loveromwe',
          my_order: 'MyOrder',
        }
        if (val !== undefined) {
          Object.assign(newTypeMap, {
            shop_multiple_sets: `multiple-sets-${val}`,
            looks_we_love: `looks-we-love-${val}`,
            survey: `survey-${val}`,
            about_us: `article-${val}`,
            ...GAME_TYPES.map(game => ({ [game]:  `${t}-${val}` }))?.reduce((target, item) => ({...target, ...item}), {}),
            campaign: val,
            webLink: val,
            pickId: val,
            sku: val,
            real: val,
            real_1: val,
            real_level_1: val,
            real_2: val,
            real_level_2: val,
          })
        }
        // const autoCompleteNo = num => (+num < 10 ? `0${num}` : num)
        return t && Object.keys(newTypeMap).includes(t) ? newTypeMap[t] : t
      },
      // TODO: 待删除
      changeToNewTypeForSensors() {
        return ''
      },
      // 获取包含 tab 名称和跳转以及来源的 label
      getItemCSGaListName({
        gaListName = '',
        tabName = 0,
        hrefType = 0,
        hrefTarget = 0,
        ici = 0,
        withoutCs = false,
        withoutHrefTarget = false,
      }) {
        const changeToNewTarget = (t) => (hrefType === 'sku' ? 0 : t)
        const field_1 = `${tabName || 0} ${
          hrefType ? this.changeToNewType(hrefType) : hrefType || 0
        } `
        const field_2 = withoutHrefTarget
          ? ''
          : ` ${changeToNewTarget(hrefTarget) || 0}`
        const CS = withoutCs ? 0 : `${field_1}${field_2}`
        const name = `${gaListName}_CS=${CS}`
        return ici === 0 ? name : `${name}_RN=${ici || 0}`
      },
      // TODO: 待删除
      getScici() {
        return ''
      },
      // TODO: 待删除
      getSensorsResourceType() {
        return ''
      },
      // TODO: 待删除
      getDataScici() {
        return ''
      },
      getGaBannerListName({ hrefType, hrefTarget }) {
        const done = this.changeToNewType
        const NO_JUMP = 'nojump'
        const getNoJumpTag = (val) => done(val, hrefTarget) || NO_JUMP
        let type = getNoJumpTag(hrefType)
        return type === NO_JUMP ? type : type || hrefTarget
      },
      // 获取包含热区 id、名称和跳转的 label
      getCommonGaListName({
        gaListName = '',
        id = 0,
        name = 0,
        hrefType = '',
        hrefTarget = '',
        withoutTarget = false,
      }) {
        // id, name 不存在就补 0
        const listName = `${gaListName}_HI=${id}_HN=${name}`
        if (withoutTarget) {
          return listName
        }

        const target = this.getGaBannerListName({
          hrefType,
          hrefTarget,
        })
        return `${listName}_TY=${target}`
      },
      getRN(gaStr = '') {
        const ici = queryString.parse(window.location.search).ici || 0
        return ici === 0 && gaStr === '' ? '' : `${ici}&${gaStr || 0}`
      },
      getGoodsIdFromSku(type, target) {
        let result
        if (type === 'sku') {
          const targetValue = target.trim()
          result = this.mapToSku[targetValue]
        } else if (type === 'flash_sale') {
          result = ''
        } else {
          result = target
        }
        return result
      },
      getVisibleTabs(id, useSwiper = false) {
        const $dom = $(".j-act-anchor[data-component-data-id='" + id + "']")
        // TODO: 点击 tab 时，slideVisibleClass 会失效，故多加个 .swiper-slide.active 类名选择
        let visibleTabs = []
        if (useSwiper) {
          visibleTabs = [
            ...Array.from($dom.find('.j-slide-visible')),
            ...Array.from(
              $dom.find('.j-swiper-container .swiper-slide.active')
            ),
          ]
        } else {
          visibleTabs = Array.from(
            $dom.find('.j-swiper-container .j-swiper-slide')
          )
        }
        const $swiperDom = $dom.find('.j-swiper-container')
        return [visibleTabs, $dom, $swiperDom]
      },
      getAnchorTabList(visibleTabs, exposedIndexArr = []) {
        const tabListArr = []
        const indexArr = []
        const reg = /(\d*)(?=`)/
        const compare = (a, b) => {
          const [prev] = a.match(reg)
          const [next] = b.match(reg)
          return prev - next
        }
        visibleTabs.length &&
          visibleTabs.forEach((tab) => {
            const anchorIndex = $(tab).data('anchorIndex')
            const anchorName = $(tab).data('anchorName')
            const tabList = `${+anchorIndex + 1}\`${anchorName}`

            if (!exposedIndexArr.includes(anchorIndex)) {
              tabListArr.push(tabList)
              indexArr.push(anchorIndex)
            }
          })
        return [indexArr, [...new Set(tabListArr)].sort(compare).join(',')]
      },
      gaAddPromoReport(params) {
        // const {
        //   category = '专题页',
        //   action = '',
        //   setAction = '',
        //   position = 0,
        //   label = '',
        //   beacon = false,
        // } = params

        // const ignoreActions = [
        //   'ViewBanner',
        //   'ShowHotZone',
        //   'ViewItems',
        //   'ShowViewAll',
        //   'ViewVideo',
        //   'ShowCalendar',
        //   'ShowLottery',
        //   'ViewCollect',
        //   'ShowRankingList',
        //   'ShowCalendar',
        // ]
        // if (ignoreActions.includes(action)) return
        // const addPromo = {
        //   name: label,
        //   position,
        // }

        // if (Array.isArray(position)) {
        //   addPromo.position = position.map((p) => +p + 1).join('-')
        // } else {
        //   addPromo.position = +position + 1 // 坑位默认从 1 开始
        // }
        // const payload = {
        //   category,
        //   action,
        //   label,
        // }
        // if (beacon) {
        //   payload.beacon = undefined
        // }
      },
      gaAddProductReport(params) {
        // const {
        //   goods = {},
        //   category = '列表页',
        //   action = 'ClickItems',
        //   position = 0,
        //   // setAction = '',
        //   value = '', // 只有加车或收藏等需要备注的需要加
        //   label = '',
        //   setAction = true,
        //   beacon = true,
        //   widthoutAddProduct = false,
        // } = params
        // const {
        //   goods_sn: sku,
        //   productRelationID: spu,
        //   salePrice: { usdAmount = '' } = {},
        //   cat_id,
        //   unit_discount = '',
        // } = goods
        // const addProduct = {
        //   id: spu || sku,
        //   name: sku,
        //   list: label,
        //   category: cat_id,
        //   price: usdAmount,
        // }

        // if (Array.isArray(position)) {
        //   addProduct.position = position.map((p) => +p + 1).join('-')
        // } else {
        //   addProduct.position = +position + 1 // 坑位默认从 1 开始
        // }

        // const payload = {
        //   category,
        //   action,
        //   label,
        // }
        // if (beacon) {
        //   payload.beacon = undefined
        // }
        // if (value !== undefined) {
        //   payload.value = value
        // }
      },
      biViewReport(params = {}) {
        const {
          act_id = 1, // 事件类型，默认为组件类型
          mod = {}, // 公共字段
          content_type = false, // 跳转类型 ID
          content_list = false, // 跳转内容
          index = [0], // tab 索引，名称加索引
          code = '', // 优惠券码
          position = 1, // 组件的index坑位
          hotName = '', // 热区名称
          tagName = '', // tag名称
          taskId = '', // 任务id
          biHrefType = '', // bi埋点上报时使用，用于表示组件整体的跳转类型，如当flashSale组件点击商品跳转时，biHrefType表示flashSale，而hrefType为sku
          biHrefTarget = '', // bi埋点用的跳转类型
          flash_type = '', // 闪购类型
          poskey = '',
        } = params
        let act_param = {
          poskey,
          act_id,
        }
        if (flash_type) {
          act_param.flash_type = flash_type
        }
        const biSrcParam = {
          position,
          hotName,
          tagName,
          taskId,
          hrefType: biHrefType,
          hrefTarget: biHrefTarget,
        }

        switch (act_id) {
          case 1: // 组件曝光
          case 4: // view all
            if (biUtils.isActParamsPropExists(content_type)) {
              act_param.content_type = content_type
            }
            if (biUtils.isActParamsPropExists(content_list)) {
              act_param.content_list = content_list
            }
            if (index.length) {
              act_param.index = biUtils.composeIndex(index)
            }
            break
          case 2: // 商品曝光
            act_param.content_type = 6
            act_param.content_list = content_list
            if (index.length) {
              act_param.index = biUtils.composeIndex(index)
            } // tab 索引
            break
          case 3: // tab 曝光
            if (index.length) {
              act_param.index = index
            }
            break
          case 5: // 优惠券
            act_param.code = code
        }

        biUtils.biSend({
          mod,
          act_param,
          activity_name: EXPOSE_TOPIC_MAIN,
          biSrcParam,
        })
      },
      getCommonLikeReqConfig(item = {}, seriesLike = {}) {
        const { goods_img = '', goods_id, goods_sn } = item
        const { user_is_like = 0 } = seriesLike[goods_id] || {}
        const payload = {
          goods_id,
          goods_img,
          user_is_like,
          goods_sn,
        }

        return () => payload
      },
      getTestBranch() {
        const poskey = 'ActAbt'
        const { p = '' } = abtParams[poskey] || {}
        const branches = p.split('|')
        const branch = branches.find((b) => b.split(':')[0] === name_en) || ''
        const querys = this.getQuerys()
        const { test_branch = '' } = querys
        return test_branch ? test_branch : branch
      },
      getUserPath(hrefType, propItem) {
        const { replaceSpeCha, getTitle } = userPath
        const {
          name = '',
          linkTo = '',
          title = '',
          startTime,
          activityNameEn: propActivityNameEn = '',
        } = propItem
        const types = [
          'real',
          'real_1',
          'real_2',
          'real_level_1',
          'real_level_2',
          'pickId',
          'discount_list',
        ]

        if (types.includes(hrefType)) {
          if (!title && startTime) {
            const date = new Date(startTime)
            const hour = `0${date.getHours()}`.slice(-2)
            const minute = `0${date.getMinutes()}`.slice(-2)
            return replaceSpeCha(
              `Activity>${propActivityNameEn || activityName}>${hour}:${minute}`
            )
          }
          return replaceSpeCha(
            `Activity>${propActivityNameEn || activityName}>${
              name || getTitle(linkTo)
            }`
          )
        }
      },
      getQuerys() {
        return window.querys
          ? window.querys
          : (window.querys = parseQueryString(location.search))
      },
      queryGoodsSaveStats(products) {
        return new Promise(async (resolve) => {
          const items = products || this.products
          if (!items || !items.length) {
            resolve()
            return
          }
          const options = {
            langPath: this.langPath,
            products: items,
            isLogin: this.isLogin,
            showWish: true,
          }
          const res =
            (await this.fetchGoodsSaveStats) &&
            this.fetchGoodsSaveStats(options)
          resolve(res)
        })
      },
      // 获取角标和文本
      getBadgeInfo(goods = {}) {
        const {
          series_badge = {},
          brand_badge_new = {},
          is_show_plus_size = 0,
          series,
          brand,
        } = goods || {}
        const {
          image_url = '',
          corner_img_right: seriesRight,
          corner_img_left: seriesLeft,
          name: seriesName = '',
        } = series || series_badge || {}
        const { corner_img_left: brandSrc, name: brandName = '' } =
          brand || brand_badge_new || {}
        const name1 = brandName ? `${brandName} ` : ''
        const name2 = seriesName ? `${seriesName} ` : ''
        let obj = {}
        if (this.isRomwe) {
          obj.src = image_url?.length ? this.transformImg(image_url) : ''
          obj.title = name1
          return obj
        }
        // 系列
        if (seriesRight && seriesRight.length) {
          obj.seriesSrc = this.GB_cssRight
            ? this.transformImg(seriesRight)
            : this.transformImg(seriesLeft)
        }
        // 品牌
        if (brandSrc && brandSrc.length) {
          obj.brandSrc = this.transformImg(brandSrc)
        }
        obj.title = this.GB_cssRight ? `${name2}${name1}` : `${name1}${name2}`
        // 大码
        if (+is_show_plus_size) {
          const src = this.GB_cssRight
            ? 'curve-plus-r-52ab3e3dc6.png'
            : 'curve-plus-b50bac3f89.png'
          obj.plusSizeSrc = `${this.PUBLIC_CDN}/she_dist/images/plusSize/${src}`
        }
        return obj
      },
    },
  }
}
