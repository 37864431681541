/**
 * 判断当前元素是否处于视口中，当处于视口中时执行回调
 * IntersectionObserver API 是异步的，不随着目标元素的滚动同步触发
 * 底层采用requestIdleCallback()，即只有线程空闲下来，才会执行观察器
 * 这意味着，这个观察器的优先级非常低，只在其他任务执行完，浏览器有了空闲才会执行
 * eg: const io = observeViewPort({
    elements: container,
    options: {
      threshold: [0.5]
    },
    callback: () => {}
  })
 */

var isFunction = function (value) {
  return typeof value === 'function'
}

export class viewPortObserver {
  /**
   * @param {Node[]} elements 元素 dom 对象数组
   * @param {Object} options 可配置的参数
   * @param {Function} callback 回调函数
   */
  constructor({ elements, options = {}, callback }) {
    this.elements = elements
    this.options = options
    this.callback = isFunction(callback) ? callback : () => {}
    this.supportObserver = 'IntersectionObserver' in window
    this.interObserve = this.newObserver()
    this.observe()
  }

  newObserver() {
    if (this.supportObserver) {
      return new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const { intersectionRatio = 0 } = entry
            if (intersectionRatio > (this.options.threshold || 0.8)) {
              this.callback(entry)
            }
          })
        },
        {
          // root: null,           // 容器内滚动。root属性指定目标元素所在的容器节点（即根元素）
          // rootMargin: '0px',    // 定义根元素的margin，用来扩展或缩小 rootBounds 这个矩形的大小，，从而影响 intersectionRect 交叉区域的大小
          threshold: [0.8], // threshold 属性决定了什么时候触发回调函数。它是一个数组，0.5 表示元素在视口中展示了 50% 之后触发回调
          ...this.options,
        }
      )
    } else {
      const { root = window } = this.options
      const ev = this.bindScrollEvent.bind(this)
      return {
        observe: () => {
          root.addEventListener('scroll', ev, false)
        },
        unobserve: () => {
          root.removeEventListener('scroll', ev, false)
        },
        disconnect: () => {},
      }
    }
  }

  bindScrollEvent() {
    const clientHeight =
      window.innerHeight || document.documentElement.clientHeight
    const { threshold = [0.8], rootMargin = '0' } = this.options
    const [, , rootMarginBottom] = this.getRootMargin(rootMargin, clientHeight)
    const el = this.elements.length ? this.elements : [this.elements]
    for (let i = 0; i < el.length; i++) {
      const entry = el[i]
      const { height, top } = entry.getBoundingClientRect()
      if (clientHeight + rootMarginBottom - top > height * threshold[0]) {
        this.callback({
          intersectionRatio: clientHeight - top / height,
        })
        this.unObserve()
      }
    }
  }

  getRootMargin(rootMargin = '0', clientHeight) {
    const items = rootMargin.trim().split(/ +/)
    let rootMarginArray = []
    const defaultRootMargin = ['0px', '0px', '0px', '0px']
    switch (items.length) {
      case 0:
        rootMarginArray.push(...defaultRootMargin)
        break
      case 1:
        rootMarginArray.push(...Array.from({ length: 4 }, () => items[0]))
        break
      case 2:
        rootMarginArray.push(...items, ...items)
        break
      case 3:
        rootMarginArray.push(...items, items[1])
        break
      case 4:
        rootMarginArray.push(...items)
    }
    if (!rootMarginArray.every((_) => _.match(/^-?\d+(px|%)/))) {
      console.error(
        "DOMException: Failed to construct 'IntersectionObserver': rootMargin must be specified in pixels or percent."
      )
      return defaultRootMargin
    }
    if (clientHeight !== undefined) {
      const getValue = (n) => {
        const percentResult = n.match(/^(-?\d+)%$/)
        const pixelsResult = n.match(/^(-?\d+)px$/)
        if (percentResult) {
          return (clientHeight * percentResult[1]) / 100
        } else if (pixelsResult) {
          return +pixelsResult[1]
        } else {
          console.log(n)
          return 0
        }
      }
      rootMarginArray = rootMarginArray.map((item) => {
        return getValue(item)
      })
    }
    return rootMarginArray
  }

  observe() {
    this.interObserve.observe(this.elements)
  }

  unObserve() {
    this.interObserve.unobserve(this.elements)
    this.interObserve.disconnect()
  }
}
