import { getBiSrcParams } from 'public/src/pages/act_common/sa.js'
import { abtservice } from 'public/src/services/abt'

export const CLICK_TOPIC_MAIN = 'click_topic_main'

export const biUtils = {
  has: function (o, v) {
    return Object.prototype.hasOwnProperty.call(o, v)
  },
  isActParamsPropExists: function (p) {
    return p || p === ''
  },
  composeIndex: function (arr = []) {
    var arrIndex = arr.concat()
    if (arrIndex.length > 1) {
      // 如果坑位和 tab 名称写反了，要调换过来
      if (!Number.isInteger(arrIndex[0])) {
        arrIndex = arrIndex.reverse()
      }
    }

    arrIndex[0] += 1 // 上报的位置按 1 开始
    return arrIndex.filter((v) => v).join('`')
  },
  biSend: function (params) {
    // 最终调用该方法发送自有埋点
    var {
      act_param = {},
      activity_name = '',
      mod = {},
      biSrcParam = {},
    } = params
    // 转换下 content_type 为对应的跳转类型 id
    const { content_type = '', content_list = '' } = act_param
    var { activityNameEn = '' } = window.configActData
    if (
      this.has(act_param, 'content_type') &&
      !Number.isInteger(content_type)
    ) {
      act_param.content_type = window.mapToHrefType[content_type] || 1
      act_param.content_list = content_type ? content_list : 'nojump'
      // 游戏合并为58， 上报内容为 游戏类型`游戏标识符
      if (act_param.content_type == 58) {
        act_param.content_list = content_type + '`' + content_list
      }
    }

    var common_param = {
      mod1_id: window.mod1_id, // 专题 id
      mod2_id: mod.compId, // 组件 id
      mod3_id: mod.dataId, // 组件内容 id
      mod4_id: mod.styleId, // 样式 id
      act_id: act_param.act_id,
    }

    // bi埋点的页面参数和事件参数增加来源参数src_module和src_identifier
    const src_param = getBiSrcParams({
      mapToHrefType: window.mapToHrefType,
      activity_name,
      content_type: act_param.content_type,
      content_list: act_param.content_list,
      activityId: window.mod1_id,
      activityNameEn,
      componentTypeNameEn: mod.componentTypeNameEn,
      componentName: mod.componentName,
      ...biSrcParam,
    })

    let abtest = ''
    if(act_param.poskey) {
      abtest = abtservice.getUserAbtResultForAnalysis({ posKeys: act_param.poskey }).sa
      delete act_param.poskey
    }
    act_param.abtest = abtest
    var activity_param = $.extend(common_param, act_param, src_param)
    sa(
      'send',
      {
        activity_name,
        activity_param,
      },
      params.beacon && window.beacon
    )
  },
}

export const biClickReport = (params = {}) => {
  const {
    act_id = 1, // 事件类型，默认为组件类型
    mod = {}, // 公共字段
    content_type = false, // 跳转类型 ID
    content_list = false, // 跳转内容
    index = [0], // tab 索引，名称加索引
    code = '', // 优惠券码
    received = 0, // 优惠券领取成功
    beacon = false,
    activity_name = CLICK_TOPIC_MAIN, // 事件名
    options = {}, // 其余自定义参数
    withoutIndex = false, // 不带 index 字段
    position = 1, // 组件的index坑位
    hotName = '', // 热区名称
    tagName = '', // tag名称
    taskId = '', // 任务id
    biHrefType = '', // bi埋点上报时使用，用于表示组件整体的跳转类型，如当flashSale组件点击商品跳转时，biHrefType表示flashSale，而hrefType为sku
    biHrefTarget = '', // bi埋点用的跳转类型
    flash_type = '', // 闪购类型
    poskey = '',
  } = params

  let act_param = {
    act_id,
    poskey,
  }
  if (flash_type) {
    act_param.flash_type = flash_type
  }

  const biSrcParam = {
    position,
    hotName,
    tagName,
    taskId,
    hrefType: biHrefType,
    hrefTarget: biHrefTarget,
  }

  if (withoutIndex) {
    index.length = 0
  }

  /*
   * 4 - 点赞
   * 6 - 浮层点击颜色
   * 7 - 浮层点击尺码
   * 8 - 点击开始抽奖
   * 10 - 注册组件点击复制链接
   */
  switch (act_id) {
    case 1: // 跳转点击
    case 2: // 商品点击
    case 4: // 商品点赞
    case 5: // 列表加车点击
      if (biUtils.isActParamsPropExists(content_type)) {
        act_param.content_type = content_type
      }
      if (biUtils.isActParamsPropExists(content_list)) {
        act_param.content_list = content_list
      }
      if (index.length) {
        act_param.index = biUtils.composeIndex(index)
      }
      break
    case 3: // tab 点击
    case 13: // pk 点击
      if (index.length) {
        act_param.index = biUtils.composeIndex(index)
      }
      break
    case 9: // 优惠券领取
      act_param.code = code
      act_param.received = received
  }

  biUtils.biSend({
    mod,
    act_param,
    activity_name,
    beacon,
    biSrcParam,
  })
}

export const getGoodsContentList = ({ goods = {}, index = 0 }) => {
  if (goods.salePrice == null) {
    goods.salePrice = {}
  }
  // goods_id`sku_id`spu_id`坑位`页码`运营位置`流量标识`置顶标识`pri_在售价格`其他标识
  const { goods_id = '', goods_sn: sku = '', productRelationID: spu = '', salePrice: { usdAmount = '' } = {}, thisPage = 1, mall_code, quickship = 0 } = goods
  return [goods_id, sku, spu, +index + 1, +thisPage, 1, '', '', `pri_${usdAmount}`, `${quickship ? `quickship_1` : ''}`, '', `mall_${mall_code || '-'}`].join('`')
}
