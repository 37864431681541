// 所有游戏枚举(PC)
export const GameTypeStringEnum = {
  // 打卡（已废弃)
  SIGN: 'sign',
  // 堆箱子
  STACK_BOX: 'boxs',
  // 礼盒雨
  GIFT_BOX_RAIN: 'gifts',
  // 旧版集卡（已废弃)
  COLLECTCARD: 'cards',
  // 打地鼠
  DIGLETT: 'hitgame',
  // 接水果
  FRUIT_RAIN: 'giftbasket',
  // 萌宠大作战
  PET_UP: 'pet_up',
  // 点亮心愿
  LIGHT_WISH: 'newyearwish',
  // 抽奖-大转盘
  LUCKY_DRAW_WHEEL: 'lucky_draw_wheel',
  // 抽奖-扭蛋机
  LUCKY_DRAW_CAPSULE_EGGS: 'lucky_draw_capsule_eggs',
  // 抽奖-九宫格
  LUCKY_DRAW_BOX: 'lucky_draw_nine_palace_grid',
  // 抽奖-砸金蛋
  LUCKY_DRAW_GOLDEN_EGGS: 'lucky_draw_golden_eggs',
  // 抽奖-点灯
  LUCKY_DRAW_DIWALI: 'lucky_draw_diwali',
  // 抽奖-备用
  LUCKY_DRAW_BAK: 'lucky_draw_bak',
  // 飞跃障碍物
  LEAP: 'tapandwin',
}

export const GameTypeNumberEnum = {
  [GameTypeStringEnum.SIGN]: 0,
  [GameTypeStringEnum.STACK_BOX]: 1,
  [GameTypeStringEnum.GIFT_BOX_RAIN]: 2,
  [GameTypeStringEnum.COLLECTCARD]: 3,
  [GameTypeStringEnum.DIGLETT]: 4,
  [GameTypeStringEnum.FRUIT_RAIN]: 8,
  [GameTypeStringEnum.PET_UP]: 10,
  [GameTypeStringEnum.LIGHT_WISH]: 11,
  [GameTypeStringEnum.LUCKY_DRAW_WHEEL]: 12,
  [GameTypeStringEnum.LUCKY_DRAW_CAPSULE_EGGS]: 13,
  [GameTypeStringEnum.LUCKY_DRAW_BOX]: 14,
  [GameTypeStringEnum.LUCKY_DRAW_GOLDEN_EGGS]: 15,
  [GameTypeStringEnum.LUCKY_DRAW_DIWALI]: 16,
  [GameTypeStringEnum.LUCKY_DRAW_BAK]: 17,
  [GameTypeStringEnum.LEAP]: 19,
}

// 所有游戏类型
export const GAME_TYPES = Object.values(GameTypeStringEnum)

export const GAME_JUMP_TYPE = 58
