import { viewPortObserver } from 'activity/common/observeViewPort'
import { patternEmail as _patternEmail } from '@shein/common-function'
import { addPrefixPoskey } from 'public/src/services/abt/core/utils.js'

/**
 * 小于10补0
 * @param num
 * @returns {*}
 */
export const autoCompleteNo = (num) => (+num < 10 ? `0${num}` : num)

// 邮箱正则
export function patternEmail(email) {
  return _patternEmail({ email })
}

// 阻止默认事件
export const preventDefault = (e) => {
  if (e.preventDefault) {
    e.preventDefault()
  } else {
    e.returnValue = false
  }
}

/**
 * 倒计时构造函数
 * @param _time 倒计时起始剩余时间，可选，单位ms
 */
export const Timer = function (_time = 0) {
  const _s = 1000 // 时间间隔
  let _countTimer = null

  const _formatTime = (time) => {
    const oneSeconds = 1000
    const oneMinute = oneSeconds * 60
    const oneHour = oneMinute * 60
    const oneDay = oneHour * 24
    const d = Math.floor(time / oneDay)
    const h = Math.floor((time % oneDay) / oneHour)
    const m = Math.floor((time % oneHour) / oneMinute)
    const s = Math.floor((time % oneMinute) / oneSeconds)
    return {
      d,
      D: autoCompleteNo(d),
      h,
      H: autoCompleteNo(h),
      m,
      M: autoCompleteNo(m),
      s,
      S: autoCompleteNo(s),
    }
  }

  const _count = (t) => {
    this.leftTime = t
    this.leftTimeObj = _formatTime(t)
    if (t > _s) t -= _s
    else if (t > 0) {
      t = 0
    } else clearInterval(_countTimer)
    return t
  }

  /**
   * 重置倒计时时间
   * @param time 单位ms
   */
  this.start = (time) => {
    if (isNaN(+time)) {
      console.error(
        `Failed argument type: Invalid argument \`time\` of type \`${typeof time}\` supplied, expected \`number\``
      )
      return
    }
    let _t = time > 0 ? +time : 0
    _t = _count(_t) // 初始化【必要】
    if (_t > 0) {
      clearInterval(_countTimer)
      _countTimer = setInterval(() => {
        _t = _count(_t)
      }, _s)
    }
  }

  this.start(_time)
}

/**
 * 初始化 swiper，为了解决异步组件时生成的 placeholder 组件，导致swiper初始化为数组的问题
 * @param {*} path swiper 绑定的类
 * @param {*} config swiper 的配置
 */
export const newSwiper = (path, config) => {
  const swiper = new Swiper(path, config)
  return swiper.length > 1 ? swiper[0] : swiper
}

/**
 * 防抖方法
 * @param {*} fn 需要防抖的对象
 * @param {*} time 间隔时间
 */
export function debounce(fn, time) {
  let timer
  return function () {
    const ctx = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.call(ctx, args)
    }, time || 800)
  }
}

export function replaceHrefSpeCha(href) {
  if (typeof href != 'string') return href
  let hrefOrigin = href && href.match(/^http(s)?:\/\/(.*?)\//g)
  if (hrefOrigin) {
    hrefOrigin = hrefOrigin[0]
    if (
      hrefOrigin &&
      !hrefOrigin.match('.shein.') &&
      !hrefOrigin.match('.romwe.')
    ) {
      return href
    }
  }
  //搜索排除
  if (/pdsearch/.test(href)) return href
  var pathBegin = href.indexOf('://') > 0 ? href.indexOf('://') + 2 : 0
  var seachBegin =
    href.indexOf('?', pathBegin) > 0
      ? href.indexOf('?', pathBegin)
      : href.length
  var protocol = href.substring(0, pathBegin)
  var pathName = href.substring(pathBegin, seachBegin)
  var search = href.substring(seachBegin, href.length)
  pathName = pathName.replace(/[><#@$\s'"%+&]|[\u4e00-\u9fa5]/g, '-')
  href = (protocol + pathName + search).replace(/-{2,}/g, '-')
  return href
}

export function template() {
  let str = arguments[arguments.length - 1] || ''
  for (let i = 0; i < arguments.length - 1; i++) {
    const re = new RegExp('\\{' + i + '\\}', 'gm')
    str = str.replace(re, arguments[i])
  }
  return str
}

/**
 * 判断当前元素是否处于视口中，当处于视口中时执行回调
 * @param {Object} payload 参数详解见 class observeViewPort
 */
export function observeViewPort(payload) {
  return new viewPortObserver(payload)
}

// poskey补全
export const handlePoskey = (poskey = '') => {
  if (!poskey) return ''
  const { IS_RW } = gbCommonInfo || {}
  if (poskey === 'ActDefault') {
    return IS_RW ? `RPcActDefault` : 'ShActDefaultPc'
  }
  return addPrefixPoskey(poskey)
}

/**
 * 将对象的属性全部转换为小写
 * @param {Object} target 目标对象
 * @returns 
 */
export function lowercaseObject(target = {}) {
  const result = {}

  Object.entries(target).forEach(([key, value]) => {
    const newKey = typeof key === 'string' ? key.toLowerCase() : key
    result[newKey] = value
  })

  return result
}