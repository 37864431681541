import { GAME_TYPES } from 'public/src/pages/act_common/const/game'
/*
 * bi埋点的页面参数和事件参数增加来源参数src_module和src_identifier
 * 新增资源位埋点需求20211126 wiki.pageId=846334114
 */

function changeToNewTypeForBi(t, val = 0) {
  if (!t) return `noJump_0`
  if (t === 'Video' && !val) return `singleVideo_0`
  const newTypeMap = {
    default: `noJump_0`,
    others: `noJump_0`,
    real: `${t}_${val}`,
    real_1: `real_${val}`,
    real_level_1: `real_${val}`,
    real_2: `real_${val}`,
    real_level_2: `real_${val}`,
    pickId: `itemPicking_${val}`,
    select: `itemPicking_${val}`,
    daily_new: `dailynew_0`,
    dailyNew: `dailynew_0`,
    flash_sale: `flashsale_0`,
    discount_list: `sheinpicks_0`,
    SheinPicks: `sheinpicks_0`,
    SHEINPicks: `sheinpicks_0`,
    group: `sheinpicks_0`,
    campaign: `activity_${val}`,
    activity: `activity_${val}`,
    activityNew: `activity_${val}`,
    activityOld: `activity_${val}`,
    sku: `goodsid_${val}`,
    webLink: `url_${val}`,
    show_checkin: `checkin_0`,
    video_list: `videoList_0`,
    live_status: `liveEntrace_0`,
    live_list: `liveList_0`,
    live_replay: `singleReplay_${val}`,
    live_preview: `singlePreview_${val}`,
    video_detail: `singleVideo_${val}`,
    Video: `singleVideo_${val}`,
    giftcard: `${t}_0`,
    survey: `${t}_${val}`,
    trial_center: `freetrial_0`,
    shop_multiple_sets: `lookBook_${val}`,
    looks_we_love: `looksWeLove_${val}`,
    about_us: `article_${val}`,
    ...GAME_TYPES.map(game => ({ [game]: `game_${val}` }))?.reduce((target, item) => ({ ...target, ...item }), {}),
    my_order: `myOrder_0`,
    shein_x: `designerRecruitment_0`,
    shein_x_info: `playersdetail_${val}`,
    couponcenter: `${t}_0`,
    categroy: `${t}_${val}`,
    media: `${t}_0`,
    coming_soon: `comingsoon_0`,
    self_information: `selfinformation_0`,
    // love_romwe: '',
    social_home: `gals_0`,
    social_tag: `gals_0`,
    outfit_detail: `gals_0`,
    wear_detail: `gals_0`,
    review_detail: `gals_0`,
    live_detail: `gals_0`,
    vote_detail: `gals_0`,
    outfit_rules: `outfitRule_0`,
    outfit_feed: `gals_0`,
    outfit_hashtag: `gals_0`,
    outfit_runway: `gals_0`,
    show_detail: `gals_0`,
    show_info_detail: `gals_0`,
    social_account_page: `gals_0`,
    lucky_draw_wheel: `${t}_${val}`,
    lucky_draw_capsule_eggs: `${t}_${val}`,
    series: `${t}_${val}`, 
    collocation: `${t}_${val}`, 
    prime: `${t}_0`,
  }
  return t && Object.keys(newTypeMap).includes(t) ? newTypeMap[t] : t
}

// 从 content_list 里面获取想要的 hrefTarget
function getHrefTargetFromContentList(content_type, content_list) {
  let hrefTarget = ''
  switch (+content_type) {
    case 6:
    case 35:
    case 36:
    case 37:
      hrefTarget = content_list
        .split(',')
        .reduce((cur, next) => {
          cur.push(next.split('`')[0])
          return cur
        }, [])
        .join(',')
      break
    default:
      hrefTarget = content_list
  }
  return hrefTarget
}

// 如果某个参数为空，默认上报为'0'
function defaultValue(val) {
  return !!val ? val : '0'
}

// 组合专题的src_identifier
export function getBiActSrcIdentifier(params) {
  let {
    position = 1,
    hrefType = '',
    hrefTarget = '',
    src_module = '',
    isNeedSrcTabPageId = false,
  } = params
  let activityId = defaultValue(params.activityId)
  let activityNameEn = defaultValue(params.activityNameEn)
  let componentTypeNameEn = defaultValue(params.componentTypeNameEn)
  let componentName = defaultValue(params.componentName)
  let tagName = defaultValue(params.tagName)
  let hotName = defaultValue(params.hotName)

  // ai={$.专题id}`an={$.专题标识符}`on={$.组件类型英文名称}`cn={$.组件名称}`hz={$.热区名称or标签名称}`ps={$.资源位位置}`jc={$.跳转参数}
  let obj = {
    src_module,
    src_identifier: `ai=${activityId}\`an=${activityNameEn}\`on=${componentTypeNameEn}\`cn=${componentName}\`hz=${
      tagName === '0' ? hotName : tagName
    }\`ps=${+position > 0 ? position : 1}\`jc=${changeToNewTypeForBi(
      hrefType,
      hrefTarget
    )}`,
  }

  if (isNeedSrcTabPageId) {
    obj.src_tab_page_id =
      typeof window != 'undefined'
        ? window.getSaPageInfo?.tab_page_id ||
          (window.SaPageInfo
            ? SaPageInfo.page_name + SaPageInfo.start_time
            : '')
        : ''
  }

  return obj
}

// 组合游戏的src_identifier
export function getBiGameSrcIdentifier(params) {
  let {
    hrefType = '',
    hrefTarget = '',
    src_module = '',
    isNeedSrcTabPageId = false,
  } = params
  let activityNameEn = defaultValue(params.activityNameEn)
  let taskId = defaultValue(params.taskId)

  const hrefTypeEnum = {
    1: 'select',
    2: 'group',
    3: 'others',
    4: 'real',
    5: 'real',
    6: 'activity',
  }
  let type = hrefTypeEnum[hrefType] || 'others'

  // gi={$.游戏标识}`ti={$.任务ID}‘jc={$.跳转参数}
  let obj = {
    src_module,
    src_identifier: `gi=${activityNameEn}\`ti=${taskId}\`jc=${changeToNewTypeForBi(
      type,
      hrefTarget
    )}`,
  }

  if (isNeedSrcTabPageId) {
    obj.src_tab_page_id =
      typeof window != 'undefined'
        ? window.getSaPageInfo?.tab_page_id ||
          (window.SaPageInfo
            ? SaPageInfo.page_name + SaPageInfo.start_time
            : '')
        : ''
  }

  return obj
}

// 获取 src_module 和 src_identifier
export function getBiSrcParams(options) {
  let {
    activity_name = '',
    content_type = 0,
    content_list = '',
    activityId = '',
    activityNameEn = '',
    componentTypeNameEn = '',
    componentName = '',
    tagName = '',
    hotName = '',
    position = 1,
    taskId = '',
    mapToHrefType = {},
    hrefType = '',
    hrefTarget = '',
  } = options

  let param = {}
  if (
    activity_name === 'expose_topic_main' ||
    activity_name === 'click_topic_main'
  ) {
    // 将 hrefType 从 content_type 还原出来
    const type = Object.keys(mapToHrefType).find(
      (key) => mapToHrefType[key] === +content_type
    )
    param = getBiActSrcIdentifier({
      src_module: 'campaign',
      activityId,
      activityNameEn,
      componentTypeNameEn,
      componentName,
      tagName,
      hotName,
      position,
      hrefType: hrefType || (type ? type : ''),
      hrefTarget: hrefType 
        ? hrefTarget
        : hrefTarget || getHrefTargetFromContentList(+content_type, content_list),
    })
  } else if (
    activity_name === 'click_get_chances_view' ||
    activity_name === 'click_get_chances_addcart' ||
    activity_name === 'click_get_chances_order'
  ) {
    param = getBiGameSrcIdentifier({
      src_module: 'game',
      activityNameEn,
      taskId,
      hrefType: content_type,
      hrefTarget: content_list,
    })
  }

  return param
}
